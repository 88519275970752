import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class PromotionService {
    private subject:BehaviorSubject<Array<any>> = new BehaviorSubject<any>([]);

    constructor(
        private apiService: ApiService
    ) {
        this.onInit()
    }

    public getData() {
        return this.subject.asObservable() 
    }

    public getValue() {
        return this.subject.getValue()
    }

    private onInit() {
        this.apiService.post('/promotion/article', { page: 1 }, false ).subscribe(jsondata => {
            if(jsondata.success) {
                this.subject.next(jsondata.data.items)
            }
        })
    }
}