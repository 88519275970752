import { Directive, ElementRef, HostListener, Output, EventEmitter } from '@angular/core';
import { ImgLoadingService } from './img.loading.service';

@Directive({
    selector: 'img',
    standalone: true,
  })
export class ImgLoadedDirective {
    @Output() onImageLoadedOrError: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(
        private el: ElementRef,
        private imgLoadingService: ImgLoadingService,
    ) {
        imgLoadingService.imageLoading(this.el.nativeElement);
        const supports = 'loading' in HTMLImageElement.prototype;

        if(supports) {
            this.el.nativeElement.setAttribute('loading', 'lazy');
        }
    }
  
    @HostListener('load')
    onLoad() {
        this.onImageLoadedOrError.emit(true)
        this.imgLoadingService.imageLoadedOrError(this.el.nativeElement);
    }
  
    @HostListener('error')
    onError() {
        this.imgLoadingService.imageLoadedOrError(this.el.nativeElement);
    }
  }