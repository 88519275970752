import { Directive, ElementRef, HostListener, Output, EventEmitter, Attribute, SimpleChanges } from '@angular/core';

@Directive({
    selector: '[imgx]',
    standalone: true,
  })
export class ImgxDirective {
    constructor(
        private el: ElementRef,
    ) {


        const supports = 'loading' in HTMLImageElement.prototype;
        if(supports) {
            this.el.nativeElement.setAttribute('loading', 'lazy');
        }
    }

    ngAfterViewInit() {
        const src = this.el.nativeElement.getAttribute('src')
        if(src && !src.startsWith('http') && !src.startsWith('/assets/')) {
            this.el.nativeElement.setAttribute('src', 'https://imgx.kbcgame.com/media/' + src)
        }
    }
  
    ngOnChanges(changes: SimpleChanges) {
        console.log(changes)
        // if(changes['src']) {
        //     const prev = changes['src'].previousValue;
        //     const current = changes['src'].currentValue
        //     if(current) {
        //         console.log(current)
        //     }
        // }
    }
}