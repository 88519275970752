import { CommonModule } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { SwiperDirective } from '@app/shared/lib/directive/swiper.directive';
import { Subscription } from 'rxjs';
import { Swiper, SwiperOptions } from 'swiper/types';
import { PromotionService } from '../../service/promotion.service';
import { RouterModule } from '@angular/router';

@Component({
    selector: 'app-swiper-notice',
    standalone: true,
    imports: [
        CommonModule,
        RouterModule,
        SwiperDirective
    ],
    templateUrl: './swiper-notice.component.html',
    styles: ``,
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SwiperNoticeComponent {
    private subs: Subscription[] = [];

    swiperOptions!:SwiperOptions
    swiperItems!:any
    swiper!:Swiper

    constructor(
        private promotionService: PromotionService
    ) {}

    ngOnInit() {
        this.swiperOptions = {
            slidesPerView: 1,
            spaceBetween: 10,
            autoplay: {
                delay: 4000,
                disableOnInteraction: false,
            },
            navigation: true,
            pagination: { clickable: true, dynamicBullets: false },
            breakpoints: {
                768: {
                    slidesPerView: 3,
                },
            },
            injectStyles: [`
                :host {
                    --swiper-theme-color: #fff;
                    --swiper-navigation-size: 18px;
                    --swiper-pagination-bottom: 0px;
                }
            `],
            on: {
                init: (swiper:Swiper)  => {
                    this.swiper = swiper;
                },
            },
        }

        this.subs.push(
            this.promotionService.getData().subscribe(v => {

                this.swiperItems = v
                if(this.swiper) {
                    this.swiper.params.loop = true
                }
                
            })
        )
    }

    ngOnDestroy(): void {
        this.subs.map(s => s.unsubscribe());
    }
}
